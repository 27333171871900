import { DetachedTooltip } from "@/components/detached-tooltip"
import { Button } from "@/components/ui/button"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { ChevronDown, ChevronRight, InfoIcon } from "lucide-react"
import { useState } from "react"
import { PredsTable } from "./annotations-table"

interface Metrics {
  f1: number
  precision: number
  recall: number
  support: number
  accuracy: number
  tp: number
  fp: number
  tn: number
  fn: number
}

interface MetricsTableProps {
  projectId: string
  metricsData: Record<string, Metrics>
  documentStatus: string
}

export function MetricsTable({ projectId, metricsData, documentStatus }: MetricsTableProps) {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({})

  const toggleRow = (task: string) => {
    setExpandedRows(prev => ({ ...prev, [task]: !prev[task] }))
  }
  
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[30px]"></TableHead>
          <TableHead className="w-[200px]">Classification Task</TableHead>
          {/* <TableHead>
            F1 Score
            <DetachedTooltip content="Harmonic mean of precision and recall">
              <InfoIcon className="inline-block w-4 h-4 ml-1 text-gray-500" />
            </DetachedTooltip>
          </TableHead> */}
          <TableHead>
            Precision
            <DetachedTooltip content="Number of true detections divided by the total number of detections">
              <InfoIcon className="inline-block w-4 h-4 ml-1 text-gray-500" />
            </DetachedTooltip>
          </TableHead>
          <TableHead>
            Recall
            <DetachedTooltip content="Number of true detections divided by the total number of actual positives">
              <InfoIcon className="inline-block w-4 h-4 ml-1 text-gray-500" />
            </DetachedTooltip>
          </TableHead>
          <TableHead>
            Support
            <DetachedTooltip content="Number of actual positives in the dataset">
              <InfoIcon className="inline-block w-4 h-4 ml-1 text-gray-500" />
            </DetachedTooltip>
          </TableHead>
          <TableHead>
            Accuracy
            <DetachedTooltip content="Number of clauses with correct label divided by the total number of clauses">
              <InfoIcon className="inline-block w-4 h-4 ml-1 text-gray-500" />
            </DetachedTooltip>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Object.entries(metricsData).map(([task, metrics]) => (
          <>
            <TableRow key={task}>
              <TableCell>
                <Button
                  variant="ghost"
                  size="sm"
                  className="h-8 w-8 p-0"
                  onClick={() => toggleRow(task)}
                  aria-label={expandedRows[task] ? "Collapse row" : "Expand row"}
                >
                  {expandedRows[task] ? (
                    <ChevronDown className="h-4 w-4" />
                  ) : (
                    <ChevronRight className="h-4 w-4" />
                  )}
                </Button>
              </TableCell>
            <TableCell className="font-medium">{task}</TableCell>
            {/* <TableCell>{metrics.f1?.toFixed(2)}</TableCell> */}
            <TableCell>{metrics.support > 0 ? metrics.precision?.toFixed(2): "-"} 
              <span className="text-xs text-slate-500"> ({metrics.fp}/{metrics.fp + metrics.tn} false pos.)</span>
              </TableCell>
            <TableCell>{metrics.support > 0 ? metrics.recall?.toFixed(2): "-"}
              <span className="text-xs text-slate-500"> ({metrics.fn}/{metrics.fn + metrics.tp} false neg.)</span>
            </TableCell>
            <TableCell>{metrics.support}
              <span className="text-xs text-slate-500"> (out of {metrics.tp + metrics.fp + metrics.tn + metrics.fn})</span>
            </TableCell>
              <TableCell>{metrics.support > 0 ? metrics.accuracy?.toFixed(2): "-"}</TableCell>
            </TableRow>
            {expandedRows[task] && (
              <TableRow>
              <TableCell colSpan={7}>
                <div className="p-4">
                  <h4 className="text-sm font-semibold mb-2">Sample Data</h4>
                    <PredsTable task={task} projectId={projectId} documentStatus={documentStatus} />
                </div>
              </TableCell>
              </TableRow>
            )}
          </>
        ))}
      </TableBody>
    </Table>
  )
}
