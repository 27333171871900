export const criteriaData = [
  // "Anti-assignment",
  "Audit rights",
  "Cap on liability",
  "Change of control",
  "Covenant not to sue",
  "Exclusivity",
  "Governing law",
  "Insurance",
  "IP ownership assignment",
  "Irrevocable or perpetual license",
  "Joint IP ownership",
  "License grant",
  // "Minimum commitment",
  "No-solicit of employees",
  "Non-compete",
  // "Non-disparagement",
  "Non-transferable license",
  "Post-termination services",
  // "Renewal term",
  // "Revenue/profit sharing",
  // "Rofr/rofo/rofn",
  // "Termination for convenience",
  "Third party beneficiary",
  // "Uncapped liability",
  "Warranty duration",
] as const satisfies string[]

export const ddCriteria = [
  {
    "title": "Audit rights",
    "description": "The right to inspect the other party's books, records, or physical locations",
  },
  {
    "title": "Cap on liability",
    "description": "The maximum amount of damages that can be recovered by one party from the other",
  },
  {
    "title": "Change of control",
    "description": "Consent or notice of the other party if a party undergoes a change of control"
  },
  {
    "title": "Covenant not to sue",
    "description": "Restriction of a party from bringing a claim against the other party"
  },
  {
    "title": "Exclusivity",
    "description": "Provides for an exclusive dealing commitment between the parties of a contract"
  },
  {
    "title": "Governing law",
    "description": "Refers to the state/country's law that governs the interpretation of a contract"
  },
  {
    "title": "Insurance",
    "description": "Requirement for a party to maintain insurance coverage"
  },
  {
    "title": "IP ownership assignment",
    "description": "Provides that intellectual property of one party becomes the property of the other party"
  },
  {
    "title": "Irrevocable or perpetual license",
    "description": "A license that cannot be revoked or that lasts indefinitely"
  },
  {
    "title": "Joint IP ownership",
    "description": "Multiple parties have ownership rights in the intellectual property"
  },
  {
    "title": "License grant",
    "description": "The right to use the other party's intellectual property"
  },
  {
    "title": "No-solicit of employees",
    "description": "Restrictions on soliciting or hiring the other party’s employees"
  },
  {
    "title": "Non-compete",
    "description": "Restrictions on the ability of a Party to compete with the other party"
  },
  {
    "title": "Non-transferable license",
    "description": "Prohibits one party to transfer, assign or sublicense IP in the contract"
  },
  {
    "title": "Post-termination services",
    "description": "Iimposes obligations on a party after the termination or expiration of a contract, including any post-termination transition, payment, transfer of IP, wind-down, last-buy, or similar commitments"
  },
  {
    "title": "Third party beneficiary",
    "description": "Provides that a non-contracting party is a beneficiary to some or all of the clauses in the contract"
  },
  {
    "title": "Warranty duration",
    "description": "The period of time that a warranty is in effect"
  }
]