import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";

export const FilterBadge = ({ text, onClick }) => {
  return (
    <Badge variant="secondary" className="px-2 py-1">
      {text}
      <Button
        variant="ghost"
        size="icon"
        className="h-4 w-4 ml-2"
        onClick={onClick}
      >
        <X className="h-3 w-3" />
      </Button>
    </Badge>
  )
}
