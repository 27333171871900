import { Icon } from "@/assets"
import SavedSearches from "@/components/savedSearches"
import { savedSearchesData } from "@/data/savedSearches"

interface MyLibraryProps {
  toggleModal?: any
  onSelect: any
}

// TODO: this file is likely unnecessary
export default function MyLibrary({ onSelect }: MyLibraryProps) {
  return (
    <SavedSearches searchData={savedSearchesData} onSelect={onSelect} />
  )
}
