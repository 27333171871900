import { DocumentSchema } from "@/api/resources"
import { ProjectStatus, Status } from "@/elements"
import DocumentDropdown from "./document/DocumentDropdown"
import { CaretRightIcon, Link1Icon } from "@radix-ui/react-icons"
import { Link } from "react-router-dom"
import { useState } from "react"
import { ChevronUpIcon, ExternalLinkIcon, Link2Icon, LinkIcon } from "lucide-react"

export default function SemanticMatchedDocuments({
  provision,
  documents,
}: {
  provision: string
  documents: DocumentSchema[]
}) {

  // const [setDocumentStatus, result] = useSetDocumentStatusMutation()
  // const setStatusAndUpdate = (newStatus: DropdownValue, index: number) => {
  //   setDocumentStatus({
  //     document_id: documents[index].document_id,
  //     status: newStatus.id,
  //   })
  // }

  const [openedMatches, setOpenedMatches] = useState<number[]>([])
  const provisionId = provision.replaceAll(" ", "_").toLowerCase()

  return (
    <div className="flex flex-col gap-4">
      <p className="text-16 text-gray-400">
        <span className="text-black">{documents.length}</span> matching documents found
      </p>

      {documents.map((document, index) => (
        <div className="border rounded-md py-4 px-6 flex flex-col gap-2">
          <div className="flex justify-between items-center">
            <div
              className="flex flex-col gap-2 flex-1"
            >
              <div>
                <div className="flex items-center gap-2">
                  <Link to={`/document/${document.document_id}?provision=${provisionId}`}>
                    <ExternalLinkIcon className="w-4 h-4" />
                  </Link>
                  <h4>
                    {document.display_name}
                  </h4>
                </div>
                <p className="text-gray-400">{document.title ?? "-"}</p>
              </div>

              <div className="flex gap-2 items-center text-14 text-gray uppercase">
                <p className="opacity-40">{document.file_type}</p>
                {document.language ? (
                  <p className="opacity-80 bg-blue-200 text-white px-1 py-0.5 rounded-md">
                    {document.language}
                  </p>
                ) : null}
                <ProjectStatus status={document.status} />
              </div>
            </div>

            {/* <Status
              values={STATUS_DROPDOWN_VALUES}
              selectedValue={findStatus(document.status) ?? null}
              setSelectedValue={(newStatus) => setStatusAndUpdate(newStatus, index)}
            /> */}
            <DocumentDropdown document={document} />
          </div>

          <ul>
            {document.semantic_matches?.map((match) => (
              <li
                key={match.idx}
                className={`transition-all rounded-sm py-1 text-15`}
              >
                <div className="flex items-start gap-2">
                  <ChevronUpIcon className={`hover:bg-gray-100 mr-2 ml-0 w-4 h-4 ${openedMatches.includes(match.idx) ? "rotate-180" : "rotate-90"}`} onClick={() => {
                  openedMatches.includes(match.idx) ?
                    setOpenedMatches((prev) => prev.filter((idx) => idx != match.idx)) : 
                    setOpenedMatches((prev) => [...prev, match.idx])
                }} />
                  <span
                    className={`focus:outline-none w-full overflow-ellipsis overflow-hidden ${openedMatches.includes(match.idx) ? "" : "line-clamp-2"}`}
                  >
                  {match.text}
                </span>
                <Link to={`/document/${document.document_id}?provision=${provisionId}#${match.idx}`}>
                  <ExternalLinkIcon className="w-4 h-4" />
                </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}
