"use client"

import * as React from "react"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"

interface DetachedTooltipProps {
  content: React.ReactNode
  children: React.ReactNode
  sideOffset?: number
}

export function DetachedTooltip({ content, children, sideOffset = 8 }: DetachedTooltipProps) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="inline-block relative">{children}</span>
        </TooltipTrigger>
        <TooltipContent 
          sideOffset={sideOffset} 
          className="absolute z-50 animate-in fade-in-0 zoom-in-95 min-w-40"
        >
          {content}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

