import { Icon } from "@/assets"
import Keywords from "@/components/keywords"
import { useView } from "@/context/ViewContext"
import { savedSearchesData } from "@/data/savedSearches"
import { searchType } from "@/types/search"
import { useState } from "react"
import Search from "./searchBar/search"
import SearchMatch from "./searchMatch"
import SimilarityCriteria from "./similarityCriteria"
import { SavedSearchSchema } from "@/api/resources/types"
import { handlePasteFunction } from "@/components/left/main/searchBar/search"
import { useDocumentContext } from "@/components/left/DocumentContext"
import { NavigationMenuDemo } from "@/components/left/main/similarityCriteria/CriteriaMenu"
import Library from "@/components/left/main/searchBar/library";
import DocumentOverview from "@/components/left/DocumentOverview";

export default function Main({
  keywords,
  setKeywords,
  matches,
  activeMatch,
  setActiveMatch,
}) {
  const [query, setQuery] = useState<string>("")
  // const [keywords, setKeywords] = useState<string[]>([]);
  const [allData, setAllData] = useState<searchType[]>(savedSearchesData) // FIXME
  const { splitView, toggleView } = useView()

  const {
    semanticSearchClass: chosenCriteria,
    setSemanticSearchClass: setChosenCriteria,
  } = useDocumentContext()

  const isEmptySearch = !(chosenCriteria != null || keywords.length > 0)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && query.trim().length > 0) {
      e.preventDefault()
      console.log("Searching for:", query)

      setKeywords([...keywords, query])
      setQuery("")
    }
  }

  const onSelect = (savedSearch: SavedSearchSchema) => {
    savedSearch ? setKeywords(savedSearch.keywords) : setKeywords([])
  }

  const handlePaste = handlePasteFunction(keywords, setKeywords)

  const deleteKeyword = (idx: number) => {
    const existingKeywords = [...keywords]
    existingKeywords.splice(idx, 1)
    setKeywords(existingKeywords)
  }

  const handleSave = (e: { preventDefault: () => void }, name: string) => {
    e.preventDefault()
    const newData = { name, keywords }

    setAllData([...allData, newData])
    setKeywords([])
  }

  if (splitView) {
    return <>Split view not supported</>
  }

  return (
    <div className="pt-5">
      {
        keywords.length <= 0 && <NavigationMenuDemo />
      }
      {
        chosenCriteria === null && (
          <div>
            <Search
              query={query}
              handleChange={handleChange}
              handleKeyPress={handleKeyPress}
              handlePaste={handlePaste}
              placeholder="Type keywords ⏎, or paste from excel"
              classname="py-6"
            />
            <div className="flex justify-end w-full">
              <Library onSelect={onSelect} />
            </div>
          </div>
        )
      }
      {chosenCriteria !== null && (
        <SimilarityCriteria
          chosenCriteria={chosenCriteria}
          clearSearch={() => {
            setChosenCriteria(null)
          }}
        />
      )}
      {
        keywords.length > 0 && (
          <SearchMatch
            handleSave={handleSave}
            matches={matches}
            keywords={keywords}
            activeMatch={activeMatch}
            setActiveMatch={setActiveMatch}
          >
            <button
              onClick={() => {
                setKeywords([])
              }}
              className="px-3 py-2 mt-3 mb-3 flex center
            gap-2 bg-bgSecondary rounded-5
            text-12"
            >
              <Icon name="Close" />
              Clear search
            </button>
            <Keywords
              keywords={keywords}
              parentClass={"pb-6 flex-wrap"}
              childClass={
                "rounded-6 bg-background-darkGrey text-text-darkGrey font-500 text-16 leading-4 flex gap-2 items-center w-fit p-2"
              }
              deleteKeyword={deleteKeyword}
            />
          </SearchMatch>
        )
      }
      {
        chosenCriteria === null && keywords.length <= 0 && (
          <DocumentOverview />
        )
      }
    </div>
  )
}
