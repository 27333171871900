import { Icon } from "@/assets"
import { FilterBadge } from "@/components/Project/FilterBadge";

interface KeywordsProps {
  keywords: string[]
  parentClass: string
  deleteKeyword?: (idx: number) => void
  childClass?: string
  iconRight?: boolean
}

export default function Keywords({
  keywords,
  deleteKeyword,
  parentClass,
  childClass,
  iconRight = true,
}: KeywordsProps) {
  return (
    <div
      className={`overflow-auto max-h-[300px] flex gap-2 flex-wrap`}
    >
      {keywords?.map((keyword, i) => (
        <FilterBadge key={i} text={keyword} onClick={() => deleteKeyword && deleteKeyword(i)} />
      ))}
    </div>
  )
}
