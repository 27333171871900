import { useGetPredsTableQuery } from "@/api/resources"
import { Button } from "@/components/ui/button"

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "@/components/ui/table"
import { SimpleSelect } from "@/elements/simple-select"
import { useEffect, useState } from "react"

export function PredsTable({projectId, task, documentStatus}: {projectId: string, task: string, documentStatus: string}) {
    const {data, isLoading} = useGetPredsTableQuery({
      project_id: projectId, 
      provision: task, 
      document_status: documentStatus
    })
    const [isEditing, setIsEditing] = useState(false)
    const [editableData, setEditableData] = useState(data?.map((sample: any) => ({...sample})))

    useEffect(() => {
      setEditableData(data?.map((sample: any) => ({...sample})))
    }, [data])
  
    if (isLoading) {
      return <div>loading ...</div>
    }
    return (
        <div className="overflow-x-auto">
            {/* {isEditing ? 
                <div className="flex gap-2">
                    <Button size="sm" onClick={() => setIsEditing(false)}>Save</Button>
                    <Button variant="outline" size="sm" onClick={() => setIsEditing(false)}>Discard</Button>
                </div> : 
                <Button variant="outline" size="sm" onClick={() => setIsEditing(true)}>Edit</Button>
            } */}
            <Table>
                <TableHeader>
                    <TableRow>
                    <TableHead className="w-1/2">Text</TableHead>
                    <TableHead className="w-1/4">Prediction</TableHead>
                    <TableHead className="w-1/4">User Label</TableHead>
                    <TableHead className="w-1/4">Output</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {editableData?.map((sample: any, index: number) => (
                    <TableRow key={index}>
                        <TableCell className="whitespace-normal break-words">{sample.text}</TableCell>
                        <TableCell>{sample.prediction}</TableCell>
                        <TableCell>{isEditing ? 
                            <SimpleSelect 
                            value={sample.user_label} 
                            options={[{value: 0, label: "0"}, {value: 1, label: "1"}]} 
                            placeholder={""}
                            onValueChange={(value: number) => setEditableData(prev => prev.map((s: any, i: number) => i === index ? {...s, user_label: value} : s))} 
                        /> : sample.user_label}</TableCell>
                        <TableCell>{sample.logit}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
  }