import { useCallback, useEffect, useState } from "react";

import Document from "./document"
import GroupDocuments from "./groupDocuments"
import { DocumentSchema } from "@/api/resources"
import FilesHeader from "@/components/Project/project/FilesHeader"
import SortDropdown, { SORT_OPTIONS, SortOption } from "@/components/Project/project/SortDropdown";
import { Label } from "@/components/ui/label";
import ViewToggleButtons from "@/components/Project/view-toggle-buttons";
import FilesTable from "@/components/Project/project/FilesystemView/files-table";
import { useLocalStorage } from "@/utils/hooks";

interface FilteredDocumentsProps {
  documents?: DocumentSchema[]
  title?: string
  matches?: number
  emptyMessage?: string | null
}

const GridView = ({filteredDocuments, selected, toggleSelected, openDocumentMore, handleToggleMore, emptyMessage}) => {
  return (
    <div className="gap-4 flex flex-wrap justify-start">
      {filteredDocuments && filteredDocuments.length > 0 ? (
        filteredDocuments.map((document, idx) => (
          <Document
            key={document.document_id}
            toggleSelected={toggleSelected}
            selected={selected}
            document={document}
            isOpen={openDocumentMore === idx}
            onToggle={() => handleToggleMore(idx)}
          />
        ))
      ) : (
        <p className="text-gray text-center col-span-4">{emptyMessage}</p>
      )}
    </div>
  )
}

export default function Documents({
  title,
  documents,
  matches,
  emptyMessage = null,
}: FilteredDocumentsProps) {
  const [isGridView, setIsGridView] = useLocalStorage<boolean>("preferences.view", true)
  const [selectedSort, setSelectedSort] = useLocalStorage<SortOption>("preferences.sort", SORT_OPTIONS[0])

  const [openDocumentMore, setOpenDocumentMore] = useState<number | null>(null)
  const [filteredDocuments, setFilteredDocuments] = useState<DocumentSchema[]>()
  const [selected, setSelected] = useState<number[]>([])

  const toggleSelected = (id: number) => {
    // if (selected.includes(id))
    //   setSelected((prev) => prev.filter((prevId) => prevId !== id))
    // else setSelected([...selected, id])
  }

  const handleToggleMore = (index: number) => {
    setOpenDocumentMore((prevIndex) => (prevIndex === index ? null : index))
  }

  const handleDocGrouping = () => {
    console.log("selected documents:", selected)
    setSelected([])
  }

  const sortFn = useCallback((a: DocumentSchema, b: DocumentSchema) => {
    if (selectedSort.value == "recent") return b.created_at.localeCompare(a.created_at)
    if (selectedSort.value == "oldest") return a.created_at.localeCompare(b.created_at)
    if (selectedSort.value == "az") return a.display_name.localeCompare(b.display_name)
    if (selectedSort.value == "za") return b.display_name.localeCompare(a.display_name)
    return 0
  }, [selectedSort])

  useEffect(() => {
    const sortedDocs = documents?.toSorted(sortFn)
    setFilteredDocuments(sortedDocs)
    // filterDocuments()
  }, [documents, sortFn])

  return (
    <div className="flex flex-col gap-4">
      <FilesHeader
        title={title}
        matches={matches}
        buttons={
          <div className="flex items-center gap-6">
            <ViewToggleButtons isGridView={isGridView} setIsGridView={setIsGridView} />
            <Label htmlFor="sort-dropdown" className="text-sm font-medium">
              Sort by
            </Label>
            <SortDropdown selectedSort={selectedSort} setSelectedSort={setSelectedSort} />
          </div>
        }
      />

      {
        isGridView ? <GridView
            filteredDocuments={filteredDocuments}
            selected={selected}
            toggleSelected={toggleSelected}
            openDocumentMore={openDocumentMore}
            handleToggleMore={handleToggleMore}
            emptyMessage={emptyMessage}
          /> : <FilesTable resources={filteredDocuments} />
      }

      {selected.length > 0 && (
        <GroupDocuments handleDocGrouping={handleDocGrouping} />
      )}
    </div>
  )
}
