import { useState, useEffect, useMemo } from "react"
import { useGetProjectsQuery } from "@/api/resources"
import { useParams } from "react-router-dom"

import { useGetMetricsQuery } from "@/api/resources"
import { MetricsTable } from "./metrics-table"
import { SimpleSelect } from "@/elements/simple-select"

export default function MetricsPage() {

    const { id } = useParams()
    const { data: projects, isLoading } = useGetProjectsQuery()
    const [documentStatus, setDocumentStatus] = useState("IN_REVIEW")
  
    const project = useMemo(() => {
      if (projects) {
        return projects.find((x) => x.project_id == id)
      }
      return undefined
    }, [projects, id])
  
    // TODO: say that project doesn't exist, or show an error toast if isError
    if (!project && !isLoading) {
      return <div>project not found</div>
    }

  const { data: metrics, isLoading: metricsLoading } = useGetMetricsQuery({ project_id: id ?? "", document_status: documentStatus }, { skip: !id })

//   const [metricsData, setMetricsData] = useState<Record<string, any> | null>(null)
//   const [loading, setLoading] = useState(true)

//   useEffect(() => {
//     async function loadMetrics() {
//       const data = mockMetrics
//       setMetricsData(data)
//       setLoading(false)
//     }
//     loadMetrics()
//   }, [])

  return (
    <div className="container mx-auto px-10">
      <h1 className="text-2xl font-bold m-4">Metrics for <span className="font-normal">{project?.display_name}</span></h1>
      <div className="flex flex-row gap-4 items-center mb-4">
        <p className="text-sm text-gray-500">By document status:</p>
        <SimpleSelect
        placeholder="Select document status"
        value={documentStatus}
        onValueChange={(value) => setDocumentStatus(value)}
        options={[
          { value: "NOT_STARTED", label: "Not started" },
          { value: "IN_PROGRESS", label: "In Progress" },
          { value: "IN_REVIEW", label: "In Review" },
            { value: "DONE", label: "Done" },
          ]}
        />
      </div>
      {!id || metricsLoading ? (
        <p>Loading metrics...</p>
      ) : metrics ? (
        <div className="overflow-x-auto">
          <MetricsTable projectId={id} metricsData={metrics} documentStatus={documentStatus} />
        </div>
      ) : (
        <p>No metrics available.</p>
      )}
    </div>
  )
}
